import React from 'react';
import { Menu as SMenu } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import withTheme from '../hoc/withTheme';
import withCurrentUser from '../hoc/withCurrentUser';
import withFeature from '../hoc/withFeature';
import { menuToLinkMenu } from '../helper';

@withRouter
@withTheme
@withCurrentUser
@withFeature
class Menu extends React.Component {
  renderItems = itemSpec => {
    const { type, items, ...item } = itemSpec;

    switch (type) {
      case 'menu':
        return <SMenu.Menu {...item}>{items.map(this.renderItems)}</SMenu.Menu>;
      case 'section':
        const { title, ...itemSpec } = item;
        return (
          <SMenu.Item {...itemSpec}>
            <SMenu.Header>{item.title}</SMenu.Header>
            {items.map(this.renderItems)}
          </SMenu.Item>
        );
      default:
        return <SMenu.Item {...item} />;
    }
  };

  render() {
    const {
      user,
      match: { path },
      theme,
      specs,
      feature,
      secondary,
      vertical,
      borderless,
      attached,
      className,
    } = this.props;
    const menuSpecs = menuToLinkMenu(specs(user, feature, this.props), path);

    return (
      <SMenu
        secondary={secondary}
        vertical={vertical}
        attached={attached}
        borderless={borderless}
        inverted={theme.isDark}
        className={className}>
        {menuSpecs.map(this.renderItems)}
      </SMenu>
    );
  }
}

export default Menu;
