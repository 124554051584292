import React from 'react';
import ThemeContext from '../../contexts/themeContext';

class ThemeManager extends React.Component {
  state = {
    theme: 'default',
  };

  _handleChangeTheme = theme => this.setState({ theme });

  render() {
    const { children } = this.props;
    return (
      <ThemeContext.Provider
        value={{
          theme: this.state.theme,
          changeTheme: this._handleChangeTheme,
        }}>
        {children}
      </ThemeContext.Provider>
    );
  }
}

export default ThemeManager;
