import React from 'react';
import { Container, Divider, Icon } from 'semantic-ui-react';
import { pullRight } from '../layout.scss';
import NavBarTop from './NavBarTop';

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <Container fluid={true}>
        <NavBarTop />
        <Container fluid={false}>
          {children}
          <div className="Footer">
            <Divider />
            <p className={pullRight}>
              Made with <Icon name="heart" color="red" /> in NYC
            </p>
          </div>
        </Container>
      </Container>
    );
  }
}

export default Layout;
