import React from 'react';
import withCurrentUser from './withCurrentUser';
import { Redirect } from 'react-router';

const requireAuth = (WrappedComponent, role) => {
  const roleLevels = {
    user: 100,
    admin: 500,
  };

  @withCurrentUser
  class RequireAuth extends React.Component {
    render() {
      const { user } = this.props;
      if (!user) return <Redirect to="/auth/login" />;
      if (roleLevels[user.role] < roleLevels[role])
        return <Redirect to="/auth/login" />;
      return <WrappedComponent {...this.props} />;
    }
  }

  RequireAuth.displayName = WrappedComponent.displayName;
  return RequireAuth;
};

export default requireAuth;
