import React from 'react';
import UserContext from '../../contexts/userContext';

const withCurrentUser = WrappedComponent => {
  class WithCurrentUser extends React.Component {
    render() {
      return (
        <UserContext.Consumer>
          {({ user, changeUser, logout, tokens }) => (
            <WrappedComponent
              user={user}
              tokens={tokens}
              changeUser={changeUser}
              logout={logout}
              {...this.props}
            />
          )}
        </UserContext.Consumer>
      );
    }
  }

  WithCurrentUser.displayName = WrappedComponent.displayName;
  return WithCurrentUser;
};

export default withCurrentUser;
