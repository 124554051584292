import React, { lazy, Suspense } from 'react';
const ItemExplorer = lazy(() => import('./ItemExplorer'));

const SuspenseItemExplorer = ({ layout, ...others }) => {
  const { dashboard: Layout } = layout;
  return (
    <Layout>
      <Suspense fallback={<div>Loading...</div>}>
        <ItemExplorer />
      </Suspense>
    </Layout>
  );
};

const routes = ({ requireAuth, pluginHelper }) => [
  {
    exact: true,
    path: '/dashboard/linnwork/inventories',
    component: requireAuth(pluginHelper(SuspenseItemExplorer), 'user'),
  },
];

const dashboardMenu = (user, feature) =>
  [
    {
      icon: 'table',
      content: 'Linnwork Inventories',
      name: 'linnworkinventories',
      to: '/dashboard/linnwork/inventories',
    },
  ].filter(Boolean);

export default {
  routes,
  dashboardMenu,
};
