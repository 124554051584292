import React, { lazy, Suspense } from 'react';
const ItemExplorer = lazy(() => import('./ItemExplorer'));
const MetaFieldExplorer = lazy(() => import('./MetaFieldExplorer'));
const VariantEditor = lazy(() => import('./VariantEditor'));
const ImageEditor = lazy(() => import('./ImageEditor'));

const SuspenseImageEditor = ({ layout, ...others }) => {
  const { dashboard: Layout } = layout;
  return (
    <Layout>
      <Suspense fallback={<div>Loading...</div>}>
        <ImageEditor />
      </Suspense>
    </Layout>
  );
};

const SuspenseVariantEditor = ({ layout, ...others }) => {
  const { dashboard: Layout } = layout;
  return (
    <Layout>
      <Suspense fallback={<div>Loading...</div>}>
        <VariantEditor />
      </Suspense>
    </Layout>
  );
};

const SuspenseItemExplorer = ({ layout, ...others }) => {
  const { dashboard: Layout } = layout;
  return (
    <Layout>
      <Suspense fallback={<div>Loading...</div>}>
        <ItemExplorer />
      </Suspense>
    </Layout>
  );
};

const SuspenseMetaFieldExplorer = ({ layout, ...others }) => {
  const { dashboard: Layout } = layout;
  return (
    <Layout>
      <Suspense fallback={<div>Loading...</div>}>
        <MetaFieldExplorer />
      </Suspense>
    </Layout>
  );
};

const routes = ({ requireAuth, pluginHelper }) => [
  {
    exact: true,
    path: '/dashboard/shopify/products',
    component: requireAuth(pluginHelper(SuspenseItemExplorer), 'user'),
  },
  {
    exact: true,
    path: '/dashboard/shopify/metafields',
    component: requireAuth(pluginHelper(SuspenseMetaFieldExplorer), 'user'),
  },
  {
    exact: true,
    path: '/dashboard/shopify/products/:id/variants',
    component: requireAuth(pluginHelper(SuspenseVariantEditor), 'user'),
  },
  {
    exact: true,
    path: '/dashboard/shopify/products/:id/images',
    component: requireAuth(pluginHelper(SuspenseImageEditor), 'user'),
  },
];

const dashboardMenu = (user, feature) =>
  [
    {
      type: 'section',
      title: 'Shopify',
      items: [
        {
          type: 'menu',
          items: [
            {
              icon: 'table',
              content: 'Products',
              name: 'shopifyProducts',
              to: '/dashboard/shopify/products',
            },
            {
              icon: 'table',
              content: 'Metafields',
              name: 'shopifyMetafields',
              to: '/dashboard/shopify/metafields',
            },
          ],
        },
      ],
    },
  ].filter(Boolean);

export default {
  routes,
  dashboardMenu,
};
