import React from 'react';
import withTheme from './hoc/withTheme';

@withTheme
class Body extends React.Component {
  componentDidMount() {
    document.body.classList.toggle('dark', this.props.theme.isDark);
  }
  componentWillReceiveProps(nextProps) {
    document.body.classList.toggle('dark', nextProps.theme.isDark);
  }
  componentWillUnmount() {
    document.body.classList.remove('dark');
  }
  render() {
    return this.props.children;
  }
}

export default Body;
