import React, { lazy, Suspense } from 'react';
const Dashboard = lazy(() => import('./Dashboard'));
const ProductExplorer = lazy(() => import('./ProductExplorer'));
const ProductDetail = lazy(() => import('./ProductDetail'));

const SuspenseDashboard = ({ layout, ...others }) => {
  const { dashboard: Layout } = layout;
  return (
    <Layout>
      <Suspense fallback={<div>Loading...</div>}>
        <Dashboard />
      </Suspense>
    </Layout>
  );
};

const SuspenseProductExplorer = ({ layout, ...others }) => {
  const { dashboard: Layout } = layout;
  return (
    <Layout>
      <Suspense fallback={<div>Loading...</div>}>
        <ProductExplorer />
      </Suspense>
    </Layout>
  );
};

const SuspenseProductDetail = ({ layout, ...others }) => {
  const { dashboard: Layout } = layout;
  return (
    <Layout>
      <Suspense fallback={<div>Loading...</div>}>
        <ProductDetail />
      </Suspense>
    </Layout>
  );
};

const routes = ({ requireAuth, pluginHelper }) => [
  {
    exact: true,
    path: '/dashboard/admin/amazontrends',
    component: requireAuth(pluginHelper(SuspenseDashboard), 'admin'),
  },
  {
    exact: true,
    path: '/dashboard/amazonproducts',
    component: requireAuth(pluginHelper(SuspenseProductExplorer), 'user'),
  },
  {
    exact: true,
    path: '/dashboard/amazonproducts/detail/:asin?',
    component: requireAuth(pluginHelper(SuspenseProductDetail), 'user'),
  },
];

const dashboardAdminMenu = (user, feature) =>
  [
    feature('amazontrends') && {
      icon: 'amazon',
      content: 'Amazon Trends',
      name: 'amazontrends',
      to: '/dashboard/admin/amazontrends',
    },
  ].filter(Boolean);

const dashboardMenu = (user, feature) =>
  [
    feature('amazonproducts') && {
      type: 'section',
      title: 'Amazon',
      items: [
        {
          type: 'menu',
          items: [
            {
              icon: 'amazon',
              content: 'Amazon Products',
              name: 'amazontrends',
              to: '/dashboard/amazonproducts',
            },
            {
              icon: 'amazon',
              content: 'Product Detail',
              name: 'amazonproductdetail',
              to: '/dashboard/amazonproducts/detail',
            },
          ],
        },
      ],
    },
  ].filter(Boolean);

export default {
  routes,
  dashboardAdminMenu,
  dashboardMenu,
};
