import { Link } from 'react-router-dom';

export const menuToLinkMenu = (items, active) => {
  return items.map(
    ({ key, content, children, name, to, type, items, ...others }, i) => {
      switch (type) {
        case 'section':
        case 'menu':
          return {
            key: key || name || i,
            type,
            items: menuToLinkMenu(items, active),
            ...others,
          };

        default:
          return {
            key: key || name || i,
            content: content || children,
            active: to === active,
            as: to ? Link : undefined,
            name,
            to,
            ...others,
          };
      }
    }
  );
};
