import React, { useContext } from 'react';
import ThemeContext from '../contexts/themeContext';

const useTheme = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  return {
    current: theme,
    change: changeTheme,
    isDark: theme === 'dark',
  };
};

export default useTheme;
