import React from 'react';
import importedComponent from 'react-imported-component';
import requireAuth from '../components/hoc/requireAuth';
import Home from '../components/Home';

import AuthRoutes from './authRoutes';
import AdminRoutes from './adminRoutes';
import plugins from '../plugins';
import DashboardLayout from '../components/layout/dashboard';

const PluginRoutes = plugins.reduce(
  (routes, plugin) => [
    ...routes,
    ...plugin.routes({
      requireAuth,
      pluginHelper: Component => props => (
        <Component layout={{ dashboard: DashboardLayout }} {...props} />
      ),
    }),
  ],
  []
);

const AsyncDashboard = importedComponent(() =>
  import(/* webpackChunkName:'Dashboard' */ '../components/dashboard/Dashboard')
);

const AsyncNoMatch = importedComponent(() =>
  import(/* webpackChunkName:'NoMatch' */ '../components/NoMatch')
);

const AsyncContents = importedComponent(() =>
  import(/* webpackChunkName:'Contents' */ '../components/dashboard/Contents/index')
);

const AsyncContentCreate = importedComponent(() =>
  import(/* webpackChunkName:'Contents' */ '../components/dashboard/Contents/Create')
);

const AsyncComingSoon = importedComponent(() =>
  import(/* webpackChunkName:'ComingSoon' */ '../components/ComingSoon')
);

const routes = [
  {
    exact: true,
    path: '/',
    component: Home,
  },
  ...AuthRoutes,
  ...AdminRoutes,
  ...PluginRoutes,
  {
    exact: true,
    path: '/dashboard',
    component: requireAuth(AsyncDashboard),
  },
  {
    exact: true,
    path: '/contents',
    component: requireAuth(AsyncContents),
  },
  {
    exact: true,
    path: '/contents/create',
    component: requireAuth(AsyncContentCreate),
  },
  {
    exact: true,
    path: '/comingsoon',
    component: AsyncComingSoon,
  },
  {
    component: AsyncNoMatch,
  },
];

export default routes;
