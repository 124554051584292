import React from 'react';
import UserContext from '../../contexts/userContext';
import LocalStorageStore from '../store/LocalStorageStore';
import { self, refreshToken } from '../../actions/users';
import ZenError from '../../models/error.model';
import * as Sentry from '@sentry/browser';

@LocalStorageStore
class UserManager extends React.Component {
  state = {
    user: null,
    userTokens: null,
  };

  componentWillMount() {
    const { getLocalStore } = this.props;
    const userStore = getLocalStore('user');
    this.setState(userStore);
  }

  componentDidMount() {
    if (this.state.user) this._handleGetMe();
  }

  _handleGetMe = async () => {
    const userRes = await self(this.state.user._id, {
      Authorization: `Bearer ${this.state.userTokens.accessToken}`,
    });

    if (userRes.error) {
      if (userRes.messages[0] === ZenError.TokenExpired) {
        // try refresh token
        const newToken = await refreshToken(
          this.state.user.email,
          this.state.userTokens.refreshToken
        );

        // logout if unable to obtain new token
        if (newToken.error) return this._handleLogout();

        // set new token
        this._handleChangeUser({ user: this.state.user, tokens: newToken });
      } else {
        return this._handleLogout();
      }
    } else {
      this._handleChangeUser({ user: userRes, tokens: this.state.userTokens });
    }
  };

  _handleChangeUser = ({ user, tokens }) => {
    this.setState({ user, userTokens: tokens });

    // update localstorage
    const { setLocalStore } = this.props;
    setLocalStore('user', { user, userTokens: tokens });

    // update sentry scope
    Sentry.configureScope(scope => {
      scope.setUser(user);
    });
  };
  _handleLogout = () => this._handleChangeUser({ user: null, tokens: null });

  render() {
    const { user, userTokens } = this.state;
    const { children } = this.props;

    return (
      <UserContext.Provider
        value={{
          user,
          changeUser: this._handleChangeUser,
          auth: !!user,
          tokens: userTokens,
          logout: this._handleLogout,
        }}>
        {children}
      </UserContext.Provider>
    );
  }
}

export default UserManager;
