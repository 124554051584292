import React from 'react';
import FeatureContext from '../../contexts/featureContext';

const withFeature = WrappedComponent => {
  class WithFeature extends React.Component {
    render() {
      return (
        <FeatureContext.Consumer>
          {({ feature }) => (
            <WrappedComponent feature={feature} {...this.props} />
          )}
        </FeatureContext.Consumer>
      );
    }
  }

  WithFeature.displayName = WrappedComponent.displayName;
  return WithFeature;
};

export default withFeature;
