import importedComponent from 'react-imported-component';
import requireAuth from '../components/hoc/requireAuth';

const AsyncUsers = importedComponent(() =>
  import(/* webpackChunkName:'AdminUsers' */ '../components/dashboard/admin/Users')
);

const AsyncNotifications = importedComponent(() =>
  import(/* webpackChunkName:'AdminNotifications' */ '../components/dashboard/admin/Notifications')
);

const AsyncScheduler = importedComponent(() =>
  import(/* webpackChunkName:'AdminScheduler' */ '../components/dashboard/Scheduler')
);

const AsyncScripts = importedComponent(() =>
  import(/* webpackChunkName:'AdminScripts' */ '../components/dashboard/admin/Scripts')
);

const AsyncReport = importedComponent(() =>
  import(/* webpackChunkName:'AdminReport' */ '../components/dashboard/Report')
);

const AsyncItemScanner = importedComponent(() =>
  import(/* webpackChunkName:'AdminItemScanner' */ '../components/dashboard/ItemScanner')
);

export default [
  {
    exact: true,
    path: '/dashboard/admin/users',
    component: requireAuth(AsyncUsers, 'admin'),
  },
  {
    exact: true,
    path: '/dashboard/admin/notifications',
    component: requireAuth(AsyncNotifications, 'admin'),
  },
  {
    exact: true,
    path: '/dashboard/admin/scheduler',
    component: requireAuth(AsyncScheduler, 'admin'),
  },
  {
    exact: true,
    path: '/dashboard/admin/scripts',
    component: requireAuth(AsyncScripts, 'admin'),
  },
  {
    exact: true,
    path: '/dashboard/admin/report',
    component: requireAuth(AsyncReport, 'admin'),
  },
  {
    exact: true,
    path: '/dashboard/admin/itemScanner',
    component: requireAuth(AsyncItemScanner, 'admin'),
  },
];
