import React from 'react';
import withCurrentUser from '../hoc/withCurrentUser';
import FeatureContext from '../../contexts/featureContext';
import features from '../../features';

@withCurrentUser
class FeatureManager extends React.Component {
  state = {
    features: [],
  };

  componentWillMount() {
    const { user } = this.props;

    const qualifyFeatures = Object.entries(features)
      .filter(([feature, requirement]) => {
        if (typeof requirement === 'function') return requirement(user);
        return !!requirement;
      })
      .map(([feature]) => feature);

    this.setState({
      features: qualifyFeatures,
    });
  }

  _handleCheckFeature = feature => {
    return this.state.features.indexOf(feature) > -1;
  };

  render() {
    const { children } = this.props;
    return (
      <FeatureContext.Provider value={{ feature: this._handleCheckFeature }}>
        {children}
      </FeatureContext.Provider>
    );
  }
}

export default FeatureManager;
