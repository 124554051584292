import React, { Suspense, lazy } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';

import FeatureManager from './store/FeatureManager';
import ThemeManager from './store/ThemeManager';
import UserManager from './store/UserManager';
import GraphQLManager from './store/GraphQLManager';
import Loading from './Loading';
import Body from './Body';
import routes from '../routes';

class App extends React.Component {
  render() {
    return (
      <UserManager>
        <ThemeManager>
          <FeatureManager>
            <GraphQLManager>
              <Body>
                <Suspense fallback={Loading}>
                  <Router>
                    <div>
                      <Switch>
                        {routes.map((route, i) => (
                          <Route {...route} key={route.path || i} />
                        ))}
                      </Switch>
                    </div>
                  </Router>
                </Suspense>
              </Body>
            </GraphQLManager>
          </FeatureManager>
        </ThemeManager>
      </UserManager>
    );
  }
}

export default App;
