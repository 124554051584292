import React, { lazy, Suspense } from 'react';
const EmailEditor = lazy(() => import('react-email-editor'));

const Editor = ({ layout, ...others }) => {
  const { dashboard: Layout } = layout;
  return (
    <Layout>
      <Suspense fallback={<div>Loading...</div>}>
        <EmailEditor {...others} />
      </Suspense>
    </Layout>
  );
};

const routes = ({ requireAuth, pluginHelper }) => [
  {
    exact: true,
    path: '/dashboard/admin/notifications/emailEditor',
    component: requireAuth(pluginHelper(Editor), 'admin'),
  },
];

const dashboardAdminMenu = (user, feature) =>
  [
    feature('emailEditor') && {
      icon: 'mail',
      content: 'Email Editor',
      name: 'adminEmailEditor',
      to: '/dashboard/admin/notifications/emailEditor',
    },
  ].filter(Boolean);

export default {
  routes,
  dashboardAdminMenu,
};
