import React from 'react';
import withCurrentUser from './withCurrentUser';
import { Redirect } from 'react-router';

const guessOnly = WrappedComponent => {
  @withCurrentUser
  class GuestOnly extends React.Component {
    render() {
      const { user } = this.props;
      if (!!user) return <Redirect to="/dashboard" />;
      return <WrappedComponent {...this.props} />;
    }
  }

  GuestOnly.displayName = WrappedComponent.displayName;
  return GuestOnly;
};

export default guessOnly;
