import EmailEditor from './EmailEditor';
import WechatWork from './WechatWork';
import GoogleTrends from './GoogleTrend';
import AmazonTrends from './AmazonTrend';
import LinnWork from './LinnWork';
import Shopify from './Shopify';

const Plugins = [
  EmailEditor,
  WechatWork,
  GoogleTrends,
  AmazonTrends,
  LinnWork,
  Shopify,
];

export const dashboardAdminMenus = Plugins.filter(
  plugin => plugin.dashboardAdminMenu
).map(plugin => plugin.dashboardAdminMenu);

export const dashboardMenus = Plugins.filter(
  plugin => plugin.dashboardMenu
).map(plugin => plugin.dashboardMenu);

export default Plugins;
