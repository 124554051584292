import React from 'react';

const localStorageStore = WrappedComponent => {
  class LocalStorageStore extends React.Component {
    state = {};

    componentWillMount() {
      this.setState(JSON.parse(localStorage.getItem('zenLocalStorage')) || {});
    }

    getLocalStore = key => {
      return this.state[key];
    };

    setLocalStore = (key, value) => {
      this.setState({ [key]: value }, () => {
        localStorage.setItem('zenLocalStorage', JSON.stringify(this.state));
      });
    };

    render() {
      const { getLocalStore, setLocalStore } = this;
      return (
        <WrappedComponent
          getLocalStore={getLocalStore}
          setLocalStore={setLocalStore}
          {...this.props}
        />
      );
    }
  }

  LocalStorageStore.displayName = WrappedComponent.displayName;
  return LocalStorageStore;
};

export default localStorageStore;
