import React from 'react';
import { ApiBaseUrl } from '../../environment';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';

// Create connection link
const httpLink = new HttpLink({ uri: ApiBaseUrl + '/graphql' });

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const userStore = JSON.parse(localStorage.getItem('zenLocalStorage'))?.user;
  const token = userStore?.userTokens?.accessToken;
  const authorizationHeader = token ? `Bearer ${token}` : null;
  operation.setContext({
    headers: {
      authorization: authorizationHeader,
    },
  });
  return forward(operation);
});

const httpLinkWithAuthToken = middlewareAuthLink.concat(httpLink);

// Configure client with link
const client = new ApolloClient({
  link: httpLinkWithAuthToken,
  cache: new InMemoryCache(),
});

class GraphQLManager extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>{children}</ApolloHooksProvider>
      </ApolloProvider>
    );
  }
}

export default GraphQLManager;
