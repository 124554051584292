import React, { memo } from 'react';
import Menu from '../ui/Menu';
import { MainMenu } from './menus';

import { navbar } from './dashboard.scss';

const NavBarTop = () => (
  <Menu className={navbar} specs={MainMenu} borderless attached />
);

export default memo(NavBarTop);
