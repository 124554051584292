import request from './request';

export const register = async data =>
  await request.post('/auth/register', data);

export const login = async data =>
  await request
    .post('/auth/login', data)
    .then(res => (res.error ? res : res.data));

export const refreshToken = async (email, refreshToken) =>
  await request
    .post('/auth/refresh-token', { email, refreshToken })
    .then(res => (res.error ? res : res.data));

export const self = async (userId, headers) =>
  await request
    .get(`/users/${userId}`, headers)
    .then(res => (res.error ? res : res.data));

export const update = async (userId, data, headers) =>
  await request
    .patch(`/users/${userId}`, data, headers)
    .then(res => (res.error ? res : res.data));
