import React, { useContext } from 'react';
import ThemeContext from '../../contexts/themeContext';

const withTheme = WrappedComponent => {
  class WithTheme extends React.Component {
    render() {
      return (
        <ThemeContext.Consumer>
          {({ theme, changeTheme }) => (
            <WrappedComponent
              theme={{
                current: theme,
                change: changeTheme,
                isDark: theme === 'dark',
              }}
              {...this.props}
            />
          )}
        </ThemeContext.Consumer>
      );
    }
  }

  WithTheme.displayName = WrappedComponent.displayName;
  return WithTheme;
};

export default withTheme;
