import importedComponent from 'react-imported-component';
import guestOnly from '../components/hoc/guestOnly';

const AsyncLogin = importedComponent(() =>
  import(/* webpackChunkName:'Login' */ '../components/auth/Login')
);

const AsyncRegister = importedComponent(() =>
  import(/* webpackChunkName:'Register' */ '../components/auth/Register')
);

export default [
  {
    exact: true,
    path: '/auth/login',
    component: guestOnly(AsyncLogin),
  },
  {
    exact: true,
    path: '/auth/register',
    component: guestOnly(AsyncRegister),
  },
];
