import axios from 'axios';
import { ApiBaseUrl } from '../environment';

export const request = {
  post: async (url, data, headers = {}) => {
    try {
      const result = await axios.post(ApiBaseUrl + url, data, { headers });

      return result;
    } catch (error) {
      let messages = [];

      if (error?.response?.data?.message)
        messages.push(error?.response?.data?.message);

      if (error?.response?.data?.errors) {
        error.response.data.errors.forEach(
          err => (messages = [...messages, ...err.messages])
        );
      }

      return { error, messages };
    }
  },
  patch: async (url, data, headers = {}) => {
    try {
      const result = await axios.patch(ApiBaseUrl + url, data, { headers });

      return result;
    } catch (error) {
      let messages = [];

      if (error?.response?.data?.message)
        messages.push(error?.response?.data?.message);

      if (error?.response?.data?.errors) {
        error.response.data.errors.forEach(
          err => (messages = [...messages, ...err.messages])
        );
      }

      return { error, messages };
    }
  },
  del: async (url, headers = {}) => {
    try {
      const result = await axios.delete(ApiBaseUrl + url, { headers });

      return result;
    } catch (error) {
      let messages = [];

      if (error?.response?.data?.message)
        messages.push(error?.response?.data?.message);

      if (error?.response?.data?.errors) {
        error.response.data.errors.forEach(
          err => (messages = [...messages, ...err.messages])
        );
      }

      return { error, messages };
    }
  },
  get: async (url, data = {}) => {
    try {
      const { params, ...headers } = data;
      const result = await axios.get(ApiBaseUrl + url, {
        params,
        headers,
      });

      return result;
    } catch (error) {
      let messages = [];

      if (error?.response?.data?.message)
        messages.push(error?.response?.data?.message);

      if (error?.response?.data?.errors) {
        error.response.data.errors.forEach(
          err => (messages = [...messages, ...err.messages])
        );
      }

      return { error, messages };
    }
  },
};

export default request;
