import React from 'react';
import * as Sentry from '@sentry/browser';

const withErrorBoundary = WrappedComponent => {
  class WithErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      // logErrorToMyService(error, info);
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }

    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
          <>
            <h1>Something went wrong.</h1>
            <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>
          </>
        );
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  WithErrorBoundary.displayName = WrappedComponent.displayName;
  return WithErrorBoundary;
};

export default withErrorBoundary;
