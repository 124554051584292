import React from 'react';
import UserModel from '../models/user.model';

// Signed-in user context
const UserContext = React.createContext({
  user: null,
  changeUser: () => {},
  logout: () => {},
  auth: false,
  tokens: null,
});

export default UserContext;
