import React from 'react';
import { Container, Divider, Icon } from 'semantic-ui-react';
import withErrorBoundary from '../hoc/withErrorBoundary';
import NavBarTop from './NavBarTop';
import DashboardNavbar from '../dashboard/DashboardNavbar';

import { contentContainer } from './dashboard.scss';

@withErrorBoundary
class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <Container fluid={true}>
        <NavBarTop />
        <Container fluid={true}>
          <DashboardNavbar />
          <div className={contentContainer}>{children}</div>
        </Container>
      </Container>
    );
  }
}

export default Layout;
