import React from 'react';
import Logo from '../Logo';

export const MainMenu = (user, feature, { logout, theme }) =>
  [
    {
      key: 'logo',
      content: <Logo />,
    },
    {
      content: 'Home',
      name: 'home',
      to: '/',
    },
    !!user && {
      content: 'Dashboard',
      name: 'dashboard',
      to: '/dashboard',
    },
    !!user && {
      content: 'Item Scanner',
      name: 'itemScanner',
      to: '/dashboard/admin/itemScanner',
    },
    {
      type: 'menu',
      position: 'right',
      items: [
        !user && {
          content: 'Login',
          name: 'login',
          to: '/auth/login',
          position: 'right',
        },
        !user && {
          content: 'Register',
          name: 'register',
          to: '/auth/register',
          position: 'right',
        },
        !!user && {
          content: 'Logout',
          name: 'logout',
          onClick: () => logout(),
        },
        feature('darkMode') && {
          content: `${theme.isDark ? 'Light' : 'Dark'} Mode`,
          name: 'changeTheme',
          onClick: () => {
            theme.change(theme.isDark ? 'default' : 'dark');
          },
          position: 'right',
        },
      ].filter(Boolean),
    },
  ].filter(Boolean);
