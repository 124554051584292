export const resetPassword = false;
export const darkMode = user => !!user;
export const emailEditor = user => !!user;
export const wechatwork = user => !!user;
export const googletrends = user => !!user;
export const amazontrends = user => !!user;
export const amazonproducts = user => !!user;

export default {
  resetPassword,
  darkMode,
  emailEditor,
  wechatwork,
  googletrends,
  amazontrends,
  amazonproducts,
};
