import React, { useState } from 'react';
import Menu from '../ui/Menu';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import useTheme from 'Hooks/useTheme';
import { dashboardAdminMenus, dashboardMenus } from '../../plugins';
import { container, containerInverted } from './DashboardNavbar.scss';

export const MainMenu = (user, feature) => {
  const adminMenus = dashboardAdminMenus.reduce(
    (menus, menu) => [...menus, ...menu(user, feature)],
    []
  );

  const pluginMenus = dashboardMenus.reduce(
    (menus, menu) => [...menus, ...menu(user, feature)],
    []
  );

  return [
    {
      content: (
        <>
          <Icon name="home" />
          Overview
        </>
      ),
      name: 'dashboard',
      to: '/dashboard',
    },
    {
      type: 'section',
      title: 'Contents',
      items: [
        {
          type: 'menu',
          items: [
            {
              icon: 'list',
              content: 'List',
              name: 'contents.list',
              to: '/contents',
            },
            {
              icon: 'add',
              content: 'Add New',
              name: 'contents.create',
              to: '/contents/create',
            },
          ],
        },
      ],
    },
    {
      content: (
        <>
          <Icon name="chart bar" />
          Report
        </>
      ),
      name: 'report',
      to: '/dashboard/admin/report',
    },
    ...pluginMenus,
    user.role === 'admin' && {
      type: 'section',
      title: 'Admin',
      items: [
        {
          type: 'menu',
          items: [
            {
              icon: 'user circle',
              content: 'Users',
              name: 'adminUsers',
              to: '/dashboard/admin/users',
            },
            {
              icon: 'alarm',
              content: 'Notifications',
              name: 'adminNotifications',
              to: '/dashboard/admin/notifications',
            },
            {
              icon: 'time',
              content: 'Scheduler',
              name: 'adminScheduler',
              to: '/dashboard/admin/scheduler',
            },
            {
              icon: 'file alternate',
              content: 'Scripts',
              name: 'adminScripts',
              to: '/dashboard/admin/scripts',
            },
            ...adminMenus,
          ],
        },
      ],
    },
  ].filter(Boolean);
};

const DashboardNavBar = () => {
  const { isDark } = useTheme;
  return (
    <div
      className={classNames(container, {
        [containerInverted]: isDark,
      })}>
      <Menu specs={MainMenu} secondary vertical />
    </div>
  );
};

export default DashboardNavBar;
